window.addEventListener('DOMContentLoaded', ()=>{
    const triPanels = document.querySelectorAll('.tri-panel-cta')
    getTextHeight()
    window.addEventListener('resize', getTextHeight)
    function getTextHeight(){
        triPanels.forEach(triPanel => {
            const panels = triPanel.querySelectorAll('.panel')
            panels.forEach(panel =>{
                const textWrapper = panel.querySelector('.content .text')
                let textHeight = textWrapper.scrollHeight;
                textWrapper.parentElement.setAttribute('style',`--tooltip-text-height:${textHeight}px`)
            })
        });
    }
})