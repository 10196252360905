document.addEventListener('DOMContentLoaded', function(e) {
    let assembliesSection = document.querySelector('.featured-assemblies');

    if (assembliesSection) {
        let assemblies = document.querySelectorAll('.assembly');
        assemblies.forEach(assembly => {
			let trigger = assembly.querySelector('.btn');
            trigger.addEventListener('click', function(e) {
                expand(assembly);
            })
        })

        let closeBtns = assembliesSection.querySelectorAll('.expanded-content-card button.close');
        closeBtns.forEach(button => {
            button.addEventListener('click', function(e) {
                e.target.closest('.expanded-content-card').classList.remove('active');
            })
        })

        let quicklinks = assembliesSection.querySelectorAll('.quicklinks a');
        quicklinks.forEach(quicklink => {
            quicklink.addEventListener('click', function(e) {
                e.preventDefault();
                //turn off all active expanded
                const active_expanded = document.querySelectorAll('.expanded-content-card.active')
                active_expanded.forEach(active =>{
                    active.classList.remove('active')
                })
                let expansion = assembliesSection.querySelector(`.assembly[data-assembly="${quicklink.dataset.assembly}"]`);
                expand(expansion);
            })
        })
    }

    function expand(assembly) {
        let card = assembliesSection.querySelector(`.expanded-content-card[data-assembly="${assembly.dataset.assembly}"]`);
        if (card) {
            card.classList.add('active');
        }
    }

    const zoom_imgs = document.querySelectorAll('.featured-assemblies .zoom-img')
    zoom_imgs.forEach(zoom_img=>{
        zoom_img.addEventListener("mousemove", (e)=>{
            var zoomer = e.currentTarget;
            var offsetX = e.offsetX ? e.offsetX : e.touches[0].pageX
            var offsetY = e.offsetY ? e.offsetY : e.touches[0].pageY
            var x = offsetX/zoomer.offsetWidth*100
            var y = offsetY/zoomer.offsetHeight*100
            zoomer.style.transformOrigin = x + '% ' + y + '%';
        })
    })
    
})
