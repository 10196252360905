window.addEventListener('DOMContentLoaded', function (e) {
	let header = document.querySelector('header');

	// Hamburger functionality
	let hamburger = document.querySelector('header .hamburger');
	hamburger.addEventListener('click', function (e) {
		hamburger.classList.toggle('active');
	})
	window.addEventListener('click', function (e) {
		if (!e.target.closest('nav') && !e.target.closest('.hamburger')) {
			hamburger.classList.remove('active');
			document.querySelector('#nav-main ul.dropdown.active')?.classList.remove('active');
		}
	})

	// Turn off navigation transitions on resize to hide dropdowns
	let resizeTimer;
	window.addEventListener('resize', function (e) {
		header.classList.add('resize-stopper');
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(() => {
			header.classList.remove("resize-stopper");
		}, 400);
	})

	// Dropdowns in mobile view
	let parents = header.querySelectorAll('#nav-main > ul > li.menu-item-has-children > p');
	parents.forEach(parent => {
		parent.addEventListener('click', function (e) {
			parent.classList.toggle('active');
			parents.forEach(otherParent => {
				if (otherParent != parent) {
					otherParent.classList.remove('active');
				}
			})
		})
	})

})
