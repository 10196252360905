document.addEventListener('DOMContentLoaded', function(e) {
    let blocks = document.querySelectorAll('.title-content-blocks .content-block');

    if (blocks) {
        let currActive = document.querySelector('.title-content-blocks .content-block.active');

        window.addEventListener('scroll', function(e) {
            for (let i = blocks.length - 1 ; i >= 0 ; i--) {
                if (blocks[i].getBoundingClientRect().top <= 0) {
                    if (blocks[i] != currActive) {
                        currActive.classList.remove('active');
                        blocks[i].classList.add('active');
                        currActive = blocks[i];
                    }
                    break;
                }
            }
        })
    }
})