document.addEventListener('DOMContentLoaded', function(e) {
    let sections = document.querySelectorAll('.services-module div.max-width[data-fragment]');
    if (sections) {
        let options = {
            root: document.querySelector('#scrollArea'),
            rootMargin: '-20% 0% -80% 0%',
            threshold: 0
        }
        let observer = new IntersectionObserver(highlightService, options);

        function highlightService(entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    let currActive = document.querySelector('.services-module .bg-text span[data-fragment].active');
                    if (currActive) {
                        currActive.classList.remove('active');
                    }
                    document.querySelector(`.services-module .bg-text span[data-fragment='${entry.target.dataset.fragment}']`).classList.add('active');
                }
            })
        }

        sections.forEach(section => {
            observer.observe(section);
        })
    }
})