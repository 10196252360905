document.addEventListener('DOMContentLoaded', function(e) {
    let sliders = document.querySelectorAll('section.split-content .slider');

    if (sliders.length > 0) {
        sliders.forEach(slider => {
            let prev = slider.querySelector('button.prev');
            let next = slider.querySelector('button.next');
            let total = parseInt(slider.dataset.numSlides);
            let links = slider.querySelectorAll('.labels a');

            prev.addEventListener('click', function(e) {
                let currPos = parseInt(slider.dataset.pos);

                if (currPos == 0) {
                    currPos = total - 1;
                } else {
                    currPos--;
                }

                if (links.length > 0) {
                    slider.querySelector('.labels a.active').classList.remove('active');
                    links[currPos].classList.add('active');
                }

                slider.dataset.pos = currPos;
            })

            next.addEventListener('click', function(e) {
                let currPos = parseInt(slider.dataset.pos);

                if (currPos == total - 1) {
                    currPos = 0;
                } else {
                    currPos++;
                }

                if (links.length > 0) {
                    slider.querySelector('.labels a.active').classList.remove('active');
                    links[currPos].classList.add('active');
                }

                slider.dataset.pos = currPos;
            })
            if (links.length > 0) {
                links.forEach(link => {
                    link.addEventListener('click', function(e) {
                        e.preventDefault();
                        let target = slider.querySelector(`img[title="${link.dataset.slide}"]`);
                        slider.dataset.pos = parseInt(target.dataset.pos);
                        slider.querySelector('.labels a.active').classList.remove('active');
                        link.classList.add('active');
                    })
                })
            }

        })
    }
    
    //lightbox     
    let images = document.querySelectorAll('.split-content .slide-container img');
    // let prev = lightbox.querySelector('button.prev');
    // let next = lightbox.querySelector('button.next');
    // let imgCount = imageWrapper.getAttribute('data-count');
    const lightboxes = document.querySelectorAll('.lightbox')

    images.forEach(image => {
        image.addEventListener('click', function(e) {
            console.log(image)
            const slider = image.parentElement.parentElement.parentElement.dataset.slider
            console.log(slider)
            
            lightboxes.forEach(lightbox=>{
                if(lightbox.dataset.lightbox == slider){
                    let imageWrapper = lightbox.querySelector('.images');
                    imageWrapper.setAttribute('data-pos', image.getAttribute('data-pos'));
                    lightbox.classList.add('open');
                }
            })
        })
    })
    
    lightboxes.forEach(lightbox=>{
        const closebtn = lightbox.querySelector('.close')
        if(closebtn){
            closebtn.addEventListener('click',()=>{
                lightbox.classList.remove('open');
            })
        }
    })

    // lightbox.addEventListener('click', function(e) {
    //     if (e.target.closest('.images') == null && e.target.closest('.controls') == null) {
    //         lightbox.classList.remove('open');
    //     }
    // })

    // prev.addEventListener('click', function(e) {
    //     let currPos = parseInt(imageWrapper.getAttribute('data-pos'));
    //     if (currPos == 1) {
    //         imageWrapper.setAttribute('data-pos', imgCount);
    //     } else {
    //         imageWrapper.setAttribute('data-pos', currPos - 1);
    //     }
    // })

    // next.addEventListener('click', function(e) {
    //     let currPos = parseInt(imageWrapper.getAttribute('data-pos'));
    //     if (currPos == imgCount) {
    //         imageWrapper.setAttribute('data-pos', '1');
    //     } else {
    //         imageWrapper.setAttribute('data-pos', currPos + 1);
    //     }
    // })

})