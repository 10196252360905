window.addEventListener("DOMContentLoaded", ()=>{
    const navItems = document.querySelectorAll('.team-grid .team-nav .team-nav-item')

    //url handle
    let urlParam = location.hash.split('team=')[1]
    if(urlParam){
        navItems.forEach(navItem => {
            if(urlParam == navItem.textContent.toLowerCase().replace(' ','-')){
                removeCurActiveNav()
                navItem.classList.add('active')
                bioHandle(navItem)
                const team_section = document.querySelector('#team')
                team_section.scrollIntoView()
            }
        })
    }

    navItems.forEach(navItem => {
        navItem.addEventListener("click", ()=>{
            //nav handle
            removeCurActiveNav()
            navItem.classList.add('active')
            //update url
            history.replaceState('','', '#team='+navItem.textContent.toLowerCase().replace(' ','-'));
            //bio handle
            bioHandle(navItem)
        })
    });
    function removeCurActiveNav(){
        const curActiveNav = document.querySelector('.team-grid .team-nav .team-nav-item.active')
        if(curActiveNav){
            curActiveNav.classList.remove('active')
        }
    }
    function bioHandle(navItem){
        //bio handle
        const bios = document.querySelectorAll('.team-grid .bios .bio')
        if(navItem.textContent.toLowerCase() == 'all'){
            showAll()
        }else{
            bios.forEach(bio =>{
                bio.classList.add('hidden')
                let categories = bio.dataset.category
                if(categories){
                    categories = categories.split(' ')
                    categories.forEach(category=>{
                        if(category == navItem.textContent.toLowerCase().replace(' ','-')){
                            bio.classList.remove('hidden')
                        }
                    })
                }
            }) 
        }
    }
    function showAll(){
        const hiddenBios = document.querySelectorAll('.team-grid .bios .bio.hidden')
        hiddenBios.forEach(hiddenBio =>{
            hiddenBio.classList.remove('hidden')
        })
    }
})