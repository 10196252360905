import './navigation.js'
import './slider.js'
import './side-menu.js'
import './split-content-slider.js'
import './tri-panel'
import './charts-slider'
import './team'
import './title-content-blocks'
import './assemblies-zoom'
import './featured-assemblies'
import './mix-design'
import './form-thank-you'
import './presentation-module'
import './featured-slider'
import './overlapping-slider-bg-text'
import './portfolio-gallery.js'
import './video-hero'
import './video-play.js'

window.addEventListener('DOMContentLoaded', function (e) {
	AOS.init()
})
