document.addEventListener('DOMContentLoaded', function () {
	const section = document.querySelectorAll('.fullscreen_video')
	section.forEach(function (sec) {
		const button = sec.querySelector('.play')
		const video = sec.querySelector('video')
		const videoWrapper = sec.querySelector('.video')
		button.addEventListener('click', function () {
			videoWrapper.classList.remove('paused')
			button.classList.add('hidden')
			video.setAttribute('controls', 'controls')
			video.play()
		})
		video.addEventListener('ended', function () {
			video.currentTime = 0
			button.classList.remove('hidden')
			video.removeAttribute('controls')
			video.pause()
			videoWrapper.classList.add('paused')
		})
	})
})
