window.addEventListener('DOMContentLoaded', function(e) {

    let nextArrow = `<svg width="78" height="25" viewBox="0 0 78 25" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M65.0089 0.287048L77.4261 12.5L65.0089 24.7129L63.6065 23.287L73.5572 13.5L0 13.5V11.5L73.5572 11.5L63.6065 1.71294L65.0089 0.287048Z" fill="black"/>
	</svg>
	`;

    const nextBtn = document.querySelector('.past-events button[data-controls="next"]')
	const prevBtn = document.querySelector('.past-events button[data-controls="prev"]')
	if(!nextBtn || !prevBtn) return

    nextBtn.innerHTML = nextArrow;
    prevBtn.innerHTML = nextArrow;
})

window.addEventListener('DOMContentLoaded', function(e) {

    let nextArrow = `<span class="arrow-wrapper"><span></span></span>`;

    const nextBtn = document.querySelector('.featured-projects-slider button[data-controls="next"]')
	const prevBtn = document.querySelector('.featured-projects-slider button[data-controls="prev"]')
	if(!nextBtn || !prevBtn) return

    nextBtn.innerHTML = nextArrow;
    prevBtn.innerHTML = nextArrow;
})

window.addEventListener('DOMContentLoaded', function(e) {

    let nextArrow = `<span class="arrow-wrapper"><span></span></span>`;

    const nextBtn = document.querySelectorAll('.text-with-slider button[data-controls="next"]')
	const prevBtn = document.querySelectorAll('.text-with-slider button[data-controls="prev"]')
	if(!nextBtn || !prevBtn) return

    for (let i = 0 ; i < nextBtn.length ; i++) {
        nextBtn[i].innerHTML = nextArrow;
        prevBtn[i].innerHTML = nextArrow;
    }
})

window.addEventListener('DOMContentLoaded', function(e) {

    let nextArrow = `<svg width="42" height="88" viewBox="0 0 42 88" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M-2.18557e-06 44L41.25 0.698729L41.25 87.3013L-2.18557e-06 44Z" fill="white"/>
	</svg>`;

    const nextBtn = document.querySelectorAll('.services-module button[data-controls="next"]')
	const prevBtn = document.querySelectorAll('.services-module button[data-controls="prev"]')
	if(!nextBtn || !prevBtn) return

    for (let i = 0 ; i < nextBtn.length ; i++) {
        nextBtn[i].innerHTML = nextArrow;
        prevBtn[i].innerHTML = nextArrow;
    }
})
