window.addEventListener("DOMContentLoaded", ()=>{
    let item_need_to_hidden = []
    const viewmore_btn = document.querySelector('section.presentation-module .view-more')
    if(viewmore_btn){
        view_more()
    }
    //nav click handle
    const search_result_element = document.querySelector('.search-result')
    const navItems = document.querySelectorAll('.presentation-module .presentation-nav .presentation-nav-item')
    navItems.forEach(navItem => {
        navItem.addEventListener("click", ()=>{
            //nav handle
            const curActiveNav = document.querySelector('.presentation-module .presentation-nav .presentation-nav-item.active')
            if(curActiveNav){
                curActiveNav.classList.remove('active')
            }
            navItem.classList.add('active')

            //item handle
            const items = document.querySelectorAll('.presentation-module .item-wrapper .item')
            let hiddenitems = document.querySelectorAll('.presentation-module .item-wrapper .item.hidden')
            if(navItem.textContent.toLowerCase() == 'all'){
                hiddenitems.forEach(hiddenitem =>{
                    hiddenitem.classList.remove('hidden')
                })
            }else{
                items.forEach(item =>{
                    item.classList.add('hidden')
                    let filters = item.dataset.filters
                    if(filters){
                        filters = filters.split(',')
                        filters.forEach(filter=>{
                            if(filter.trim().toLowerCase() == navItem.textContent.toLowerCase()){
                                item.classList.remove('hidden')
                            }
                        })
                    }
                }) 
            }
            //no item to show handle
            const no_item = document.querySelector('.presentation-module .no-item')
            hiddenitems = document.querySelectorAll('.presentation-module .item-wrapper .item.hidden')
            // console.log("items:" + items.length)
            // console.log(hiddenitems.length)
            if(items.length == hiddenitems.length){
                no_item.classList.remove('hidden')
            }else{
                no_item.classList.add('hidden')
            }

            //remove not found element
            search_result_element.classList.remove('active')
            //viewmore
            if(viewmore_btn){
                view_more()
            }
        })
    });


    //sign up click handle
    const signup_btns = document.querySelectorAll('.presentation-module .sign-up')
    const signup_wrapper = document.querySelector('.presentation-module .signup-form-wrapper')
    signup_btns.forEach(element => {
        element.addEventListener('click', ()=>{
            signup_wrapper.classList.add('open')
            const presentation_select = signup_wrapper.querySelector('select#presentation-type')
            presentation_select.value = element.dataset.signup.toLowerCase()
        })
    });
    if(signup_wrapper){
        signup_wrapper.addEventListener('click', function(e) {
            if (e.target.closest('.presentation-module form') == null) {
                signup_wrapper.classList.remove('open');
            }
        })
    }


    //search handle
    const search_from = document.querySelector('form#presentation-search')
    const search_icon = document.querySelector('form#presentation-search .search-icon')
    
    if(search_icon && search_from){
        search_icon.addEventListener('click', ()=>{
            search_from.submit()
        })
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString)
    let search_string = '';
    if(urlParams.get('search')){
        search_string = urlParams.get('search').toLocaleUpperCase()
    }
    // if search string is empty then stop search
    if(search_string.length == 0){
        // search_result_element.classList.add('active')
        return
    }
    // search each presentation
    let found_flag = 0
    const presentations = document.querySelectorAll('section.presentation-module .item-wrapper .item')
    presentations.forEach(presentation_item =>{

        // search all paragraphs, title in item
        const paragraphs = presentation_item.querySelectorAll('p, h3')
        paragraphs.forEach(p =>{
            let position_found = p.textContent.toUpperCase().indexOf(search_string)
            if ( position_found > -1){ //found
                presentation_item.classList.remove('hidden')
                found_flag++
                // scroll to the first dropdown
                if(found_flag == 1){
                    presentation_item.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }
                // highlight all the search_string was found
                let highlight_str = p.textContent.substring(position_found, position_found + search_string.length)
                const reg = new RegExp(highlight_str, 'gi');
                p.innerHTML = p.innerHTML.replace(reg, function(str){
                    return '<span style="background-color:#FFFF00;color:#000"><b>'+str+'</b></span>'
                })
            }
        })
    })

    //if not found
    if(found_flag == 0){
        search_result_element.classList.add('active')
        search_from.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }


    //view more handle
    function view_more(){
        
        const number_to_show = 4
        const current_number_items = document.querySelectorAll('.presentation-module .item-wrapper .item:not(.hidden)')
        //if current items > number to show, then hidden some element
        if(current_number_items.length > number_to_show ){
            //show viewmore btn
            viewmore_btn.classList.add('active')
            item_need_to_hidden = []
            for(let i = 0; i < current_number_items.length; i++){
                if(i >= number_to_show){
                    current_number_items[i].classList.add('hidden')
                    item_need_to_hidden.push(current_number_items[i])
                }
            }
        }else{
            viewmore_btn.classList.remove('active')
        }
    }

    //viewmore btn handle
    if(viewmore_btn){
        // console.log(item_need_to_hidden)
        viewmore_btn.addEventListener('click', ()=>{
            item_need_to_hidden.forEach(el =>{
                el.classList.remove('hidden')
                viewmore_btn.classList.remove('active')
            })
        })
    }
    
})
