window.addEventListener("DOMContentLoaded", ()=>{
    const sliders = document.querySelectorAll('.charts-section .slider')
    sliders.forEach(slider =>{
        console.log(slider)
        let prev = slider.querySelector('button.prev');
        let next = slider.querySelector('button.next');
        let total = parseInt(slider.dataset.total);

        prev.addEventListener('click', function(e) {
            let currPos = parseInt(slider.dataset.pos);

            if (currPos == 0) {
                currPos = total - 1;
            } else {
                currPos--;
            }

            slider.dataset.pos = currPos;
        })

        next.addEventListener('click', function(e) {
            let currPos = parseInt(slider.dataset.pos);

            if (currPos == total - 1) {
                currPos = 0;
            } else {
                currPos++;
            }

            slider.dataset.pos = currPos;
        })
    })
})