window.addEventListener('DOMContentLoaded', function(e) {
	//use window.scrollY
	let scrollpos = window.scrollY;
	let header = document.getElementById("cat-menu");

	function add_class_on_scroll() {
		if(header){
			header.classList.add("active-menu");
		}
	}

	function remove_class_on_scroll() {
		if(header){
			header.classList.remove("active-menu");
		}
	}

	window.addEventListener('scroll', function(){ 

		scrollpos = window.scrollY;

		if(scrollpos > 900){
			add_class_on_scroll();
		}
		else {
			remove_class_on_scroll();
		}
		// console.log(scrollpos);
	});
})
