window.addEventListener("DOMContentLoaded", ()=>{
    const navItems = document.querySelectorAll('.mix-design .mix-nav .mix-nav-item')
    navItems.forEach(navItem => {
        navItem.addEventListener("click", ()=>{
            //nav handle
            const curActiveNav = document.querySelector('.mix-design .mix-nav .mix-nav-item.active')
            if(curActiveNav){
                curActiveNav.classList.remove('active')
            }
            navItem.classList.add('active')

            //item handle
            const items = document.querySelectorAll('.mix-design .item-wrapper .item')
            if(navItem.textContent.toLowerCase() == 'all'){
                const hiddenitems = document.querySelectorAll('.mix-design .item-wrapper .item.hidden')
                hiddenitems.forEach(hiddenitem =>{
                    hiddenitem.classList.remove('hidden')
                })
            }else{
                items.forEach(item =>{
                    item.classList.add('hidden')
                    let filters = item.dataset.filters
                    if(filters){
                        filters = filters.split(',')
                        filters.forEach(filter=>{
                            if(filter.trim() == navItem.textContent){
                                item.classList.remove('hidden')
                            }
                        })
                    }
                }) 
            }
        })
    });

	//lightbox     
    const gallery = document.querySelector('.mix-design .item-wrapper');
    const lightbox = document.querySelector('.mix-design .lightbox')
    if (gallery && lightbox) {
        let links = gallery.querySelectorAll('.item');
        let imageWrapper = lightbox.querySelector('.images');

        for (let i = 0 ; i < links.length ; i++) {
            links[i].addEventListener('click', function(e) {
                let num = links[i].getAttribute('data-num');
                
                imageWrapper.setAttribute('data-pos', num);
                lightbox.classList.add('open');
            })
        }

        //close lightbox
        const closebtn = lightbox.querySelector('.close')
        if(closebtn){
            closebtn.addEventListener('click',()=>{
                lightbox.classList.remove('open');
            })
        }

        lightbox.addEventListener('click', function(e) {
            if (e.target.closest('.images') == null && e.target.closest('.controls') == null) {
                lightbox.classList.remove('open');
            }
        })

        //controls
        let prev = lightbox.querySelector('button.prev');
        let next = lightbox.querySelector('button.next');
        let imgCount = imageWrapper.getAttribute('data-count');
		if(prev){
			prev.addEventListener('click', function(e) {
				let currPos = parseInt(imageWrapper.getAttribute('data-pos'));
				if (currPos == 0) {
					imageWrapper.setAttribute('data-pos', imgCount - 1);
				} else {
					imageWrapper.setAttribute('data-pos', currPos - 1);
				}
			})
		}
		if(next){
			next.addEventListener('click', function(e) {
				let currPos = parseInt(imageWrapper.getAttribute('data-pos'));
				if (currPos == imgCount - 1) {
					imageWrapper.setAttribute('data-pos', '0');
				} else {
					imageWrapper.setAttribute('data-pos', currPos + 1);
				}
			})
		}
    }
})
