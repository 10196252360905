document.addEventListener('DOMContentLoaded', () => { 
	let galleries = document.querySelectorAll('.single-portfolio .gallery');

	galleries.forEach(gallery => {
		const images = gallery.querySelectorAll('.images-wrapper img');
		const lightbox = gallery.querySelector('.lightbox');
		const closebtn = lightbox.querySelector('.close');

		images.forEach(image => {
			image.addEventListener('click', function(e) {
				lightbox.setAttribute('data-pos', image.getAttribute('data-pos'));
				lightbox.classList.add('open');
			})
		})
        if(closebtn){
            closebtn.addEventListener('click',()=>{
                lightbox.classList.remove('open');
            })
		}
	})
})
