document.addEventListener('DOMContentLoaded', () => {
	let videoWrapper = document.querySelector('.hero.video .video-wrapper');
	let heroVideo = document.querySelector('.hero.video video');
	let btn = document.querySelector('#play-hero-video');

	btn?.addEventListener('click', () => {
		videoWrapper.classList.toggle('playing');
		if (videoWrapper.classList.contains('playing')) {
			videoWrapper.classList.remove('paused');
			heroVideo.play();
			btn.innerText = 'Pause Video';
		} else {
			videoWrapper.classList.add('paused');
			heroVideo.pause();
			btn.innerText = 'Resume Video';
		}
	})

	heroVideo?.addEventListener('ended', () => {
		videoWrapper.classList.remove('paused');
		videoWrapper.classList.remove('playing');
		heroVideo.currentTime = 0;
		btn.innerText = 'Play Video';
	})
})
